module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"publicApiKey":"NmQxZjU4MTctZmMzZS00MmY0LWJiNWMtMWZjNjA0ZDhlYjE5NjM3MjQ4MDQyMTc1MjE1ODcw"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Whipworks","short_name":"Whipworks","start_url":"/","background_color":"#ffffff","theme_color":"#1a140f","display":"minimal-ui","icon":"src/images/favicon_large.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd539b46025708c8f6e320defaec5eb1"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
