exports.components = {
  "component---src-components-templates-product-page-tsx": () => import("./../../../src/components/templates/ProductPage.tsx" /* webpackChunkName: "component---src-components-templates-product-page-tsx" */),
  "component---src-components-templates-specialty-whip-page-tsx": () => import("./../../../src/components/templates/SpecialtyWhipPage.tsx" /* webpackChunkName: "component---src-components-templates-specialty-whip-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessories-tsx": () => import("./../../../src/pages/accessories.tsx" /* webpackChunkName: "component---src-pages-accessories-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-design-bullwhip-tsx": () => import("./../../../src/pages/design-bullwhip.tsx" /* webpackChunkName: "component---src-pages-design-bullwhip-tsx" */),
  "component---src-pages-design-stockwhip-tsx": () => import("./../../../src/pages/design-stockwhip.tsx" /* webpackChunkName: "component---src-pages-design-stockwhip-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

